<template>
  <div>
    <div class="text-center mb-5 w-75 mx-auto">
      <h1 class="header text-center mb-1 h3">
        <translate>Log in to Enroll Talent</translate>
      </h1>
      <p class="subtitle mt-1">
        <translate>Your all-in-one HR solution</translate>
      </p>
    </div>
    <form class="form" @submit.prevent="handleSubmit">
      <FormInput
        field="email"
        v-model="payload.email"
        :label="$gettext('Work email')"
        required
      />
      <FormInput
        field="password"
        type="password"
        v-model="payload.password"
        :label="$gettext('Password')"
        required
      />

      <SubmitButton
        :label="$gettext('Login')"
        class="btn btn-info btn-block my-4"
      />
    </form>
    <div class="text-center mt-5 w-75 mx-auto">
      <div>
        <router-link :to="{ name: 'forgot-password' }"
          ><translate>Forgot password?</translate></router-link
        >
      </div>
      <hr />
      <div>
        <translate>Don't have an account?</translate>&nbsp;
        <router-link
          :to="{ name: 'signup' }"
          class="login-register text-primary"
          ><translate>Sign Up</translate></router-link
        >.
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "@/components/FormGroups/FormInput";
import { mapActions, mapGetters } from "vuex";
import SubmitButton from "@/components/SubmitButton";
import { updateUserIntercom } from "@/utils/intercom";

export default {
  components: { SubmitButton, FormInput },
  data: () => ({ payload: { email: "", password: "" } }),
  computed: {
    ...mapGetters("me", ["user"])
  },
  methods: {
    ...mapActions("me", ["login"]),
    ...mapActions("form", ["setErrors", "submit"]),
    handleSubmit() {
      this.submit(() => this.login(this.payload))
        .then(() => {
          updateUserIntercom(this.user);
          this.$router.push({ name: "dashboard" });
        })
        .catch(() =>
          this.setErrors({ password: [this.$gettext("Invalid credentials")] })
        );
    }
  }
};
</script>
